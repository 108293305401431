<template>
  <div class="navBar">
    <div class="navBar-left">
      <img
        @click="() => this.$router.push({ path: '/dashboard' })"
        class="charity-water-logo"
        alt="Charity:water logo"
        src="../../assets/charity_water_logo.svg"
      />
      <div class="navBar-left-container">
        <h2
          @click="() => this.$router.push({ path: '/dashboard' })"
          class="navBar-left-container-title"
        >
          Sensor Dashboard
        </h2>
      </div>
    </div>
    <div class="navBar-right">
      <div v-if="this.showDropdown === true">
        <DropDown @openModal="openDialog" v-click-outside="closeDropdown" />
      </div>
      <div>
        <ChangeProviderModal
          :showDialog="showDialog"
          @closeModal="showDialog = false"
        />
      </div>
      <div @click="showDropdown = !showDropdown" class="navBar-right-userIcon">
        <p class="navBar-right-userIcon-text paragraph-medium--heavy">
          {{ user.firstName[0] }}{{ user.lastName[0] }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import DropDown from "./DropDown.vue";
import ChangeProviderModal from "./ChangeProviderModal.vue";
import vClickOutside from "v-click-outside";
export default {
  directives: { clickOutside: vClickOutside.directive },
  data() {
    return {
      showDropdown: false,
      showDialog: false,
    };
  },
  components: { DropDown, ChangeProviderModal },
  computed: {
    user() {
      return this.$store.state.loggedUser;
    },
  },
  methods: {
    closeDropdown() {
      this.showDropdown = false;
    },
    openDialog() {
      // close drop down when showing dialog
      this.showDropdown = false;
      this.showDialog = true;
    },
  },
};
</script>

<style scoped lang="scss">
.navBar {
  display: flex;
  padding: 12px 24px;
  justify-content: space-between;
  align-items: center;
  background-color: #222520;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 7;

  &-left {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-container {
      margin-left: 1.15em;
      position: relative;

      &-title {
        font-size: 16px;
        margin-left: 0.1em;
        color: white;
        &:hover {
          cursor: pointer;
        }
      }
    }

    .charity-water-logo {
      width: 23px;
      height: 32px;
      margin-right: 40px;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    &-userIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #e1e6ef;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      cursor: pointer;
      margin-right: 24px;
    }
    .search-icon {
      width: 1em;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>
