<template>
  <button class="button" :class="isLoading ? 'button--loading' : ''">
    <template v-if="isLoading">
      <LoadingSpinner class="loading-spinner" />
    </template>
    <span class="button-text">
      {{ text }}
    </span>
  </button>
</template>

<script>
import LoadingSpinner from "./LoadingSpinner.vue";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
    },
  },
  components: { LoadingSpinner },
};
</script>

<style scoped lang="scss">
.button {
  position: relative;
  &--loading {
    .button-text {
      opacity: 0;
    }
  }
  .loading {
    &-wrapper {
      position: relative;
    }
    &-spinner {
      width: 32px;
      height: 32px;
      color: white;
      top: 10px;
      left: 0;
      right: 0;
      bottom: 0;
      margin: -10px auto;
    }
  }
}
</style>
