<template>
  <div class="dropdown">
    <div class="dropdown-header">
      <div class="dropdown-header-userIcon">
        <p class="dropdown-header-userIcon-text label-large">
          {{ user?.firstName[0] }}{{ user?.lastName[0] }}
        </p>
      </div>
      <div class="dropdown-header-userDetails">
        <p class="dropdown-header-userDetails-name label-medium">
          {{ user?.firstName }} {{ user?.lastName }}
        </p>
        <p class="dropdown-header-userDetails-email paragraph-small">
          {{ user?.email }}
        </p>
      </div>
    </div>
    <div v-if="canChangePartner(roleLoggedUser)" class="dropdown-partner">
      <h3 class="dropdown-partner-label label-small">Partner</h3>
      <button
        v-if="selectedOrganization.name.length < maxPartnerNameChar"
        class="dropdown-dialog button button--transparent"
        @click="showModal"
      >
        {{ selectedOrganization.name }}
        <Pencil />
      </button>
      <button
        v-else
        class="dropdown-dialog button button--transparent"
        @click="showModal"
      >
        <md-tooltip class="partnerTooltip" md-direction="top">
          {{ selectedOrganization.name }}
        </md-tooltip>
        {{ selectedOrganization.name.substr(0, maxPartnerNameChar) + " ..." }}
        <Pencil />
      </button>
    </div>
    <div class="dropdown-settings">
      <p @click="() => this.$router.push({ path: '/settings' })">Settings</p>
    </div>
    <div class="dropdown-logOut">
      <p @click="logOut()">Log Out</p>
    </div>
  </div>
</template>

<script>
import { logoutCognito } from "../../api/cognito";
import Pencil from "../Shared/Pencil.vue";

export default {
  components: { Pencil },
  name: "DialogCustom",
  data() {
    return {
      selectedOrganization: {},
      maxPartnerNameChar: 22,
    };
  },
  computed: {
    user() {
      return this.$store.state.loggedUser;
    },
    roleLoggedUser() {
      return this.$store.getters.roleLoggedUser;
    },
  },
  methods: {
    showModal() {
      this.$emit("openModal");
    },
    logOut() {
      this.$store.dispatch("resetAllFilters");
      this.$store.dispatch("resetWaterPoints");
      this.$store.dispatch("resetHistoricalStatus");
      logoutCognito();
    },
    canChangePartner(role) {
      return role === "SuperAdmin";
    },
  },
  watch: {
    user(updatedUser) {
      this.selectedOrganization = updatedUser.selectedOrg;
    },
  },
  created() {
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    if (loggedUser.selectedOrg) {
      this.selectedOrganization = loggedUser.selectedOrg;
    } else {
      this.selectedOrganization = loggedUser.organizations[0];
    }
  },
};
</script>

<style scoped lang="scss">
.dropdown-dialog {
  justify-content: space-between;
  margin-bottom: 16px;
  border: solid 1px #e1e6ef;
  width: 95%;
}
.dropdown {
  position: fixed;
  top: calc(var(--nav-height) + 2px);
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  right: 20px;
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #f1f3f9;
    padding: 0 0 0 1em;
    &-userIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f1f3f9;
      height: 40px;
      width: 40px;
      margin-right: 1.25em;
      border-radius: 50%;
      &-text {
        font-weight: 700;
        color: #1d2433cc;
      }
    }
    &-userDetails {
      &-name {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0px;
      }
      &-email {
        margin-top: 3px;
        color: #1d2433cc;
        margin-right: 48px;
      }
    }
  }
  &-partner {
    padding: 0 0 0 1em;
    border-bottom: 2px solid #f1f3f9;

    &-label {
      color: #777777;
    }
    &-value {
      font-size: 14px;
    }
  }
  &-settings {
    padding: 0 0 0 1em;
    font-size: 14px;
    border-bottom: 2px solid #f1f3f9;
    &:hover {
      cursor: pointer;
    }
  }
  &-logOut {
    padding: 0 0 0 1em;
    font-size: 14px;
    color: red;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
