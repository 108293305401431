function isValid(value, format) {
  if (value.match(format)) {
    return true;
  } else {
    return false;
  }
}

const formats = {
  email: /\S+@\S+\.\S+/,
  phone: /^\+?[1-9][0-9]{7,14}$/,
};

export const clearFieldError = (field) => {
  const parent = field.closest(".form-field");
  parent.classList.remove("error");
};

export const validateForm = (fields) => {
  let formIsValid = true;
  fields.map((field) => {
    const parent = field.closest(".form-field");
    parent.classList.remove("error");
    // Required
    if (field.required && !field.value) {
      parent.classList.add("error");
      formIsValid = false;
    }
    // Email
    if (field.value && field.type === "email") {
      if (!isValid(field.value, formats.email)) {
        parent.classList.add("error");
        formIsValid = false;
      }
    }
  });
  return formIsValid;
};
