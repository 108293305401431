<template>
  <div v-if="visible" class="form-field--error">
    <span class="icon icon--alert">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        class="icon icon--alert"
        viewBox="0.33 0.33 13.33 13.33"
      >
        <path
          fill="currentColor"
          d="M7 7.667a.646.646 0 0 0 .476-.192.645.645 0 0 0 .19-.475V4.317a.618.618 0 0 0-.19-.467A.662.662 0 0 0 7 3.667a.644.644 0 0 0-.474.19.646.646 0 0 0-.192.476v2.684c0 .188.064.344.192.466A.66.66 0 0 0 7 7.667Zm0 2.666a.646.646 0 0 0 .476-.192.645.645 0 0 0 .19-.474.647.647 0 0 0-.19-.476A.647.647 0 0 0 7 9a.645.645 0 0 0-.474.191.646.646 0 0 0-.192.476c0 .188.064.347.192.474a.643.643 0 0 0 .474.192Zm0 3.334a6.489 6.489 0 0 1-2.6-.526 6.728 6.728 0 0 1-2.117-1.424A6.728 6.728 0 0 1 .86 9.6 6.488 6.488 0 0 1 .333 7c0-.922.176-1.789.526-2.6a6.728 6.728 0 0 1 1.425-2.117c.6-.6 1.305-1.075 2.116-1.425A6.495 6.495 0 0 1 7 .333c.922 0 1.789.175 2.6.525.811.35 1.517.825 2.117 1.425.6.6 1.075 1.306 1.425 2.117.35.811.525 1.678.525 2.6 0 .922-.175 1.789-.525 2.6a6.729 6.729 0 0 1-1.425 2.117c-.6.6-1.306 1.074-2.117 1.424a6.488 6.488 0 0 1-2.6.526Zm0-1.334c1.478 0 2.736-.52 3.775-1.558 1.04-1.039 1.559-2.297 1.559-3.775 0-1.478-.52-2.736-1.559-3.775C9.736 2.185 8.478 1.667 7 1.667c-1.478 0-2.736.519-3.775 1.558C2.186 4.264 1.667 5.522 1.667 7c0 1.478.52 2.736 1.558 3.775 1.04 1.039 2.297 1.558 3.775 1.558Z"
        />
      </svg>
    </span>
    <template v-if="customMessage">
      {{ customMessage }}
    </template>
    <template v-if="!customMessage">
      {{ errorsEnum[error] }}
    </template>
  </div>
</template>

<script>
export default {
  name: "FormFieldError",
  data() {
    const errorsEnum = {
      emptyRequiredField: "Required Field",
      invalidEmail: "Please enter a valid email address",
      invalidPhone: "Please enter a valid phone number",
      general: "An error has occurred. Please try again.",
    };
    return {
      errorsEnum,
      visible: true,
    };
  },
  props: ["error", "customMessage"],
};
</script>

<style scoped lang="scss">
.form-field--error {
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: 1em;
  padding: 6px;
  background-color: var(--clr-danger-100);
  border-radius: 4px;
  display: flex;
  align-items: center;
  input,
  select {
    border-color: red;
  }
  .icon--alert {
    color: var(--clr-danger-700);
    margin-right: 6px;
    width: 14px;
    height: auto;
    display: flex;
    align-items: center;
    svg {
      flex: none;
    }
  }
  &--tel {
    margin-bottom: 1em;
    input {
      margin-bottom: 0;
    }
    span.error {
      margin-top: 1em;
    }
  }
}
</style>
