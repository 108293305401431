<template>
  <div>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
      <md-dialog-title class="dialogTitle">
        Choose a different Partner
        <span class="closeDialog" @click="closeModal">&#10060;</span>
      </md-dialog-title>
      <md-dialog-content class="dialogContent">
        <span
          class="partnerName"
          v-for="org in organizations"
          :value="org"
          :key="org.id"
          @click="selectPartner(org)"
        >
          {{ org.name }}
          <Checkmark v-if="selectForModal.id == org.id" />
        </span>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button v-if="loadingButton" class="dialogButton">
          Loading ...
        </md-button>
        <md-button
          v-else
          class="button"
          :class="{ disabled: !isPartnerSelected }"
          @click="setPartnerSelected"
          :disabled="!isPartnerSelected"
        >
          Change Partner
        </md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>
<script>
import Checkmark from "../Shared/Checkmark.vue";
export default {
  components: { Checkmark },
  name: "DialogCustom",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectForModal: {},
      isPartnerSelected: false,
      loadingButton: false,
    };
  },
  computed: {
    organizations() {
      return this.$store.state.loggedUser.organizations;
    },
  },
  methods: {
    closeModal() {
      this.selectForModal = {};
      this.isPartnerSelected = false;
      this.loadingButton = false;
      this.$emit("closeModal");
    },
    selectPartner(partner) {
      this.selectForModal = partner;
      this.isPartnerSelected = true;
    },
    setPartnerSelected() {
      this.loadingButton = !this.loadingButton;
      this.$store.dispatch("setPartnerSelected", this.selectForModal);
      // If the user is on a detail view when changing partners, redirect to main dashboard
      if (this.$route.matched.some(({ path }) => path.includes("dashboard/"))) {
        this.$router.push("/dashboard");
      } else {
        window.location.reload();
      }

      // hypothetically the below should load all new data when you
      // want to switch partners, no idea why its not working
      // Leaving it commented out, for future reference

      // Promise.all([
      //   this.$store.dispatch("setPartnerSelected", this.selectForModal),
      //   this.$store.dispatch("fetchUsers"),
      // ]).then(() => {
      //   Promise.all([
      //     this.$store.dispatch("filterUsersByRole", "all"),
      //     this.$store.dispatch("fetchWaterPoints"),
      //     this.$store.dispatch("fetchProviders"),
      //   ]).then(() => {
      //     this.$store.dispatch("filterWaterPoints", {
      //       filterProviders: "all",
      //     });
      //     this.$store.dispatch("fetchHistoricalFlagStatus");
      //   });
      // });
    },
  },
  watch: {
    selectedOrganization(val) {
      this.selectForModal = val;
    },
  },
};
</script>

<style scoped lang="scss">
.dialogTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
}
.closeDialog {
  color: transparent;
  text-shadow: 0 0 0 black;
  font-size: 14px;
  cursor: pointer;
}
.md-button.md-theme-default {
  color: white;
}

.md-dialog-actions {
  padding: 24px;
}

.partnerTooltip {
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 0.85rem;
  font-family: Arial, Helvetica, sans-serif;
}
.dialogContent {
  padding: 0.5rem;
  max-height: 40vh;
  overflow-x: hidden;
}
.partnerName {
  padding: 16px 32px;
  width: 25vw;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.partnerName:hover {
  background-color: #f8f9fc;
}
</style>
