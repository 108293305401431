<template>
  <Transition>
    <div v-if="show" class="drawer" v-click-outside="onClose">
      <slot></slot>
    </div>
  </Transition>
</template>

<script>
import vClickOutside from "v-click-outside";
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onClose() {
      this.$emit("update:show", false);
    },
  },
  directives: { clickOutside: vClickOutside.directive },
};
</script>

<style scoped lang="scss">
.drawer {
  position: fixed;
  top: var(--nav-height);
  right: 0;
  min-width: 30vw;
  height: calc(100vh - var(--nav-height));
  z-index: 30;
  background-color: white;
  border: 1px solid #e1e6ef;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  overflow: auto;
}

.v-enter,
.v-leave-to {
  transform: translateX(100%);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.4s ease-in-out;
}
</style>
